@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/antd-provider/dist/website/mixins.less';

// Define module less variables
@search-form-cls: ~'@{ra-prefix}-search-form';
@search-form-section-cls: ~'@{search-form-cls}-section';
@search-form-row-cls: ~'@{search-form-cls}-row';
@search-field-wrapper-cls: ~'@{ra-prefix}-search-field-wrapper';
@search-text-wrapper-cls: ~'@{ra-prefix}-search-text-wrapper';
@search-domain-wrapper-cls: ~'@{ra-prefix}-search-domain-wrapper';
@search-button-wrapper-cls: ~'@{ra-prefix}-search-btn-wrapper';
@search-field-cls: ~'@{ra-prefix}-search-field';
@submit-btn-cls: ~'@{ra-prefix}-submit-btn';
@clear-btn-cls: ~'@{ra-prefix}-search-clear-btn';
@search-suggestions-cls: ~'@{ra-prefix}-search-suggestions';
@search-suggestions-vertical-cls: ~'@{search-suggestions-cls}-vertical';
@search-suggestions-horizontal-cls: ~'@{search-suggestions-cls}-horizontal';
@search-suggestions-heading-cls: ~'@{search-suggestions-cls}-heading';
@search-suggestions-list-cls: ~'@{search-suggestions-cls}-list';
@grey: #acacac;

@input-height: 44px;

:export {
  searchFormSectionCls: @search-form-section-cls;
  searchFormCls: @search-form-cls;
  searchFormRowCls: @search-form-row-cls;
  searchFieldWrapperCls: @search-field-wrapper-cls;
  searchDomainWrapperCls: @search-domain-wrapper-cls;
  searchTextWrapperCls: @search-text-wrapper-cls;
  searchButtonWrapperCls: @search-button-wrapper-cls;
  searchFieldCls: @search-field-cls;
  submitBtnCls: @submit-btn-cls;
  searchClearBtnCls: @clear-btn-cls;
  searchSuggestionsCls: @search-suggestions-cls;
  searchSuggestionsHorizontalCls: @search-suggestions-horizontal-cls;
  searchSuggestionsVerticalCls: @search-suggestions-vertical-cls;
  searchSuggestionsHeadingCls: @search-suggestions-heading-cls;
  searchSuggestionsListCls: @search-suggestions-list-cls;
}

.@{search-form-section-cls} {
  margin-bottom: 0;
}

.@{search-form-cls}.ra-form.ra-form-horizontal.ant-form {
  margin: 0 auto;
  padding: 10px 0;

  @media only screen and (min-width: @ra-screen-sm) {
    padding: 30px 0;
  }

  > .ra-heading {
    font-weight: @ra-font-weight-light;
  }

  .@{search-form-row-cls} {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;

    .@{search-field-wrapper-cls} {
      flex-grow: 1;

      @media only screen and (max-width: @ra-screen-xs-max) {
        width: 30%;
      }

      .@{clear-btn-cls} {
        padding: 10px;
      }
    }
    .@{search-text-wrapper-cls} {
      font-size: 18px;
      margin: 15px;
      line-height: @input-height;

      @media only screen and (min-width: @ra-screen-sm) {
        font-size: 24px;
        margin: 15px 20px;
      }
    }
    .@{search-domain-wrapper-cls} {
      width: 100px;

      @media only screen and (min-width: @ra-screen-sm) {
        width: 130px;
      }

      .ant-select {
        padding-top: 3px;
        padding-bottom: 3px;
        font-size: 22px;
      }
    }

    .@{search-field-cls} {
      min-height: 46px;
      font-size: 22px;

      .ant-input-prefix {
        font-size: 24px;
      }

      .ant-input {
        min-height: 46px;
        font-size: 22px;

        &:focus,
        &:active {
          color: @ra-color-primary;
        }
      }
    }

    .ant-form-explain {
      white-space: normal;
    }

    .@{search-button-wrapper-cls} {
      .ra-submit-btn {
        margin-left: 15px;
        font-size: 24px;

        @media only screen and (min-width: @ra-screen-sm) {
          font-size: 26px;
        }

        line-height: @input-height;
      }
    }
  }

  .@{search-suggestions-cls} {
    .@{search-suggestions-heading-cls} {
      margin: 20px 0;
    }

    &.@{search-suggestions-vertical-cls} {
      margin: 10px 0;

      .@{search-suggestions-list-cls} {
        li {
          .ra-btn.ra-btn-link {
            display: block;
            padding: 10px;
            font-weight: @ra-font-weight-light;

            &:hover,
            &:focus,
            &:active {
              text-decoration: none;
              background: @ra-color-background;
            }
          }
        }
      }
    }

    &.@{search-suggestions-horizontal-cls} {
      width: calc(100% - 32px);

      @media only screen and (min-width: @ra-screen-sm) {
        width: calc(100% - 39px);
      }

      .@{search-suggestions-list-cls}.ra-continuous-slider {
        .ra-continuous-slider-track {
          padding-left: 10px;
          padding-right: 10px;
        }

        .ra-continuous-slider-arrow {
          width: 20px;
          height: 38px;
          background-color: rgba(255, 255, 255, 0.7);
          top: 50%;

          &:hover {
            &:before {
              border-top: 1px solid #343434;
              border-left: 1px solid #343434;
            }
          }
        }

        .ra-continuous-slider-item {
          .ra-btn.ra-btn-link {
            display: inline-block;
            padding: 8px;
            background-color: @ra-color-grayscale-1;
            font-weight: @ra-font-weight-light;

            &:hover,
            &:focus,
            &:active {
              text-decoration: none;
              background-color: @ra-color-grayscale-10;
            }
          }
        }
      }

      ul.@{search-suggestions-list-cls} {
        white-space: nowrap;
        overflow-x: auto;

        li {
          display: inline-block;
          margin: 5px;

          .ra-btn.ra-btn-link {
            display: inline-block;
            padding: 8px;
            background-color: @ra-color-grayscale-1;
            font-weight: @ra-font-weight-light;

            &:hover,
            &:focus,
            &:active {
              text-decoration: none;
              background-color: @ra-color-grayscale-10;
            }
          }
        }
      }
    }
  }
}
